import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import {
  useUser,
  PackageCard,
  LeshenPhoneCTA,
  LinkButton,
} from '@leshen/gatsby-theme-leshen'
import {
  VariableContent,
  Columns,
  Stack,
  scrollTo,
  Typography,
} from '@leshen/ui'

import useAvailablePlans from '../hooks/useAvailablePlans'
import useHasMounted from '../hooks/useHasMounted'

import zipLogic from '../utils/zipLogic'

import Spinner from './Spinner'

const ZipPackageSection = ({ spanish }) => {
  const { userData, userLocation, setUserData } = useUser()

  // Store the userData in localStorage for persistance.
  const { availablePlans, setAvailablePlans } = useAvailablePlans(userData)

  /**
   * Check that userData is not null, which is it's initial state.
   * userData being set means it's been called at least once, and
   * the loading variable shows that it's currently loading.
   *
   * Important Note: We cannot just check for userData.loading
   * because that alone coming back false does not mean we want
   * to show the loading state. On initial page load, userData should
   * be null and we don't want to show a loading state then.
   *
   * This should be reworked in a big way, as there is a little too much
   * indirection for my taste, but there are a lot of moving parts that
   * aren't all accessible from this codebase so it will take some work.
   */
  const arePackagesLoading = userData && userData.loading

  useEffect(() => {
    /**
     * Commonly on page reload, `userData` will be null, and
     * we don't want to override localStorage with that data.
     * This does assume that `userData` will never be wiped
     * on purpose with the intent to wipe localStorage.
     * `null` is meant to be a starting point, and `userData`
     * should just be set to an empty object if the intent is
     * to clear `availablePlans`.
     */

    // this statement checks if maxmind found a zipcode and runs zip logic if so
    if (userLocation?.zipCode?.length === 5 && !userData) {
      ;(async () => {
        const packageData = await zipLogic(userLocation.zipCode, 'internet')

        setUserData({
          loading: false,
          getCenturylinkPackages: packageData,
        })
      })()
    }

    if (!userData && availablePlans) {
      return
    }
    setAvailablePlans(userData)
  }, [userData, setUserData, availablePlans, setAvailablePlans, userLocation])

  /**
   * This should be handled in the callback for the availability
   * lookup, but because that is handled through shadowing, and not
   * through passing down props from here, it is not feasible. I
   * decided it would be best to have the scrollTo and it's target
   * in the same file after considering all this.
   */
  useEffect(() => {
    if (availablePlans?.getCenturylinkPackages?.packages?.length >= 1) {
      scrollTo(`#loadingScrollTarget`)
    }
  }, [availablePlans])

  /**
   * Server-side rendering check to not have mismatching data
   * on the server, which causes a bad rehydration to layout
   * content incorrectly in some situations when using local storage.
   */
  const hasMounted = useHasMounted()
  if (!hasMounted) {
    return null
  }

  const haveGetCenturylinkPackages =
    availablePlans &&
    availablePlans.getCenturylinkPackages?.packages &&
    availablePlans.getCenturylinkPackages?.packages.length > 0

  let packageData = null

  if (
    haveGetCenturylinkPackages &&
    availablePlans.getCenturylinkPackages?.packages[0].packageName
  ) {
    packageData = spanish
      ? availablePlans.getCenturylinkPackages.packages.filter((current) =>
          current.packageName.includes('-es')
        )
      : availablePlans.getCenturylinkPackages.packages.filter(
          (current) => !current.packageName.includes('-es')
        )
  }

  return (
    <>
      {arePackagesLoading && (
        <VariableContent
          className="packages"
          alignMainContent="center"
          mainContent={
            <>
              <Typography variant="h2">
                {spanish
                  ? 'Búsqueda de paquetes en su área.'
                  : 'Searching packages in your area.'}
              </Typography>
              <Spinner />
            </>
          }
        />
      )}

      {/* Set up the section(s) that show the users packages or out of area message below the Hero */}
      {haveGetCenturylinkPackages &&
        packageData?.length > 0 &&
        !arePackagesLoading && (
          <VariableContent
            className="packages"
            alignMainContent="center"
            mainContent={
              <div>
                {userLocation.city && userLocation.zipCode && (
                  <Typography variant="h4">
                    <strong>
                      {spanish
                        ? 'Mostrando el mejor plan para'
                        : 'Showing CenturyLink plans for'}{' '}
                      {userLocation.city}, {/* eslint-disable-next-line */}
                      {userLocation.zipCode}{' '}
                    </strong>
                  </Typography>
                )}
              </div>
            }
          >
            <Stack spacing="xxl">
              <Columns>
                {packageData.map((data) => (
                  <PackageCard
                    label={data.label}
                    packageData={{
                      ...data.brandy,
                    }}
                    content={
                      <>
                        <LinkButton
                          to="/cart"
                          CustomLink={Link}
                          color="primary"
                        >
                          {spanish ? 'Ordena en línea' : 'Order Online'}
                        </LinkButton>
                        {/* Disabled lint line due to Gatsby api named
                          variable */}
                        {/* eslint-disable-next-line no-underscore-dangle */}
                        <LeshenPhoneCTA variant="feature" color="primary">
                          {spanish ? 'Llama al' : 'Call'}
                        </LeshenPhoneCTA>
                      </>
                    }
                    key={data.label}
                    modal={
                      <span>
                        <a href={spanish ? '/es/terms' : '/terms-conditions'}>
                          {spanish ? 'Ver detalles' : 'Offer Details'}
                        </a>
                      </span>
                    }
                  />
                ))}
              </Columns>
            </Stack>
          </VariableContent>
        )}

      {/**
       * If userData is not null, and its `loading` property is set to false
       * by availabilitySubmit, then a fetch request has just completed.
       * That in combination with having no beam package data should mean
       * that there are no packages for the location the user searched.
       */}
      {availablePlans &&
        !availablePlans.loading &&
        availablePlans?.getCenturylinkPackages?.packages === null && (
          <VariableContent
            mainContent={
              <>
                <Typography variant="h2">
                  {spanish
                    ? 'Estamos teniendo problemas para encontrar opciones de servicio para su área.'
                    : "We're having trouble locating service options for your area."}
                </Typography>
                <p>
                  {spanish
                    ? '¡Llámanos y uno de nuestros especialistas en Internet puede ayudarte a conectarte!'
                    : 'Give us a call and one of our Internet specialists can help get you connected!'}
                </p>
              </>
            }
            alignMainContent="center"
          />
        )}
    </>
  )
}

export default ZipPackageSection
